import React, { useEffect, useState } from "react";

import firebase from "gatsby-plugin-firebase";
import { Container, GridContainer, PageWidth } from "@util/standard";
import { Loading } from "@global";
import { useStore } from "@state/store";
import AccountTemplate from "@shared/account/accountTemplate";
import { Order } from "@state/types";
import { getDocsCollection } from "@util/firebase";
import { isBrowser } from "@util/helper";
import OrderObject from "@shared/account/orderComponents";

const Orders = () => {
  const { user } = useStore();
  const [orders, setOrders] = useState<Order[] | null>(null);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    if (!isBrowser()) return;
    const firestore = firebase.firestore();

    // const getOrders = async () => {
    //   if (user.isAdmin) {
    //     const adminOrders = await getDocsCollection(firestore.collection("orders"));
    //     const completedOrders = adminOrders?.filter(order => order.status === "complete");
    //     if (completedOrders) setOrders(completedOrders);
    //     return;
    //   } else {
    //     const orders = await getDocsCollection(
    //       firestore.collection("orders").where("uid", "==", user.id),
    //     );
    //     const completedOrders = orders?.filter(order => order.status === "complete");
    //     if (completedOrders) setOrders(completedOrders);
    //   }
    // };

    // getOrders();
  }, [user]);

  return (
    <AccountTemplate handle="properties">
      <PageWidth>
        <Container margin="0 0 35px" tabletMargin="0 0 20px" flexFlow="column nowrap">
          <h2 className="h4">Order summary</h2>
          <p>
            Thank you for choosing to market your property using HomeSell. Below is a summary of
            your package order and inclusions.
          </p>
          <p>Step 1) Review the package inclusions</p>
          <p>
            Step 2) Once you’re happy with the package you’ve chosen, click on the ‘Add property’
            button below to get started with loading your property details.
          </p>
          <p>
            Note: If something is not quite right, or if you’d like to add extras, click button to
            contact the HomeSell support team
          </p>
        </Container>
        {orders ? (
          <GridContainer width="100%" gridTemplateColumns="1fr" gap="10px">
            {orders.length > 0 ? (
              orders.map(order => (
                <OrderObject key={order.uuid} order={order} setOrders={setOrders} />
              ))
            ) : (
              <>No orders found</>
            )}
          </GridContainer>
        ) : (
          <Container flexFlow="column nowrap" width="100%" margin="35px 0" alignItems="center">
            <Loading baseColor="lightBlue" color="main" />
            <p>Retreiving order, please hang on</p>
          </Container>
        )}
      </PageWidth>
    </AccountTemplate>
  );
};

export default Orders;
